/* eslint-disable no-use-before-define */
import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { ether, logo } from "../image";
import { CustomField } from "../smallComponents/CustomFeild";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
} from "../ConnectivityAssets/hooks";
import { formatUnits, parseUnits } from "@ethersproject/units";
import Loading from "../ConnectivityAssets/loading";
import Toastify from "../ConnectivityAssets/Toastify";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useBalance, useSendTransaction, WagmiConfig } from "wagmi";
import CountdownTimer from "../smallComponents/CountDown";
import CountDownUp from "../smallComponents/CountDownUp";
import moment from "moment-timezone";
import { parseEther } from "viem";

const BuyCard = () => {
  // Loading
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showToast = (msg, type) => {
    return setAlertState({
      open: true,
      message: msg,
      severity: type,
    });
  };

  // Use States
  const [amount, setAmount] = useState(null);
  const [youWillGet, setYouWillGet] = useState(null);
  const [userBalance, setUserBalance] = useState(0);
  const [orderId, setOrderId] = useState(null);
  const [claim, setClaim] = useState(true);

  const [presaleTimeStatus, setPresaleTimerStatus] = useState(null);
  const { sendTransaction, variables } = useSendTransaction({
    config: WagmiConfig,
  });

  useEffect(() => {
    const targetDateLondon = moment.tz("2024-10-22T17:00:00", "Europe/London");
    const currentDate = moment.tz("Europe/London");

    if (currentDate.isAfter(targetDateLondon)) {
      setPresaleTimerStatus(true);
    } else {
      setPresaleTimerStatus(false);
    }
  }, []);
  // Wagmi

  const { address, isConnected } = useAccount();

  // Get User Balance
  const balanceResult = useBalance({
    address: address,
    watch: true,
  });

  // FindClaim Function

  const findClaim = useCallback(async (orderid) => {
    console.log("orderid....", orderid);
    let foundClaim = false;

    for (const id of orderid) {
      console.log("id...........", id);

      try {
        const claimOject = await preSaleReadFunction("getOrderInfo", [
          id.toString(),
        ]);
        console.log("claimOject....", claimOject);

        if (claimOject?.[2] === false) {
          setClaim(claimOject[2]);
          setOrderId(id); // Set claim to false
          foundClaim = true; // Mark that we found a false claim
          break; // Exit the loop
        }
      } catch (error) {
        console.error("Error fetching claim object:", error);
      }
    }

    // If no false claim was found, set to true
    if (!foundClaim) {
      setClaim(true);
      console.log("No false claim found");
    }
  }, []);

  // Init Function
  const init = useCallback(async () => {
    try {
      setLoading(true);
      const id = await preSaleReadFunction("getInvestorOrderIds", [address]);
      // console.log("id....", id);
      // setOrderId(id.toString());
      if (id === "" || (Array.isArray(id) && id.length === 0)) {
        setLoading(false);
        return; // Do nothing if id is an empty string or empty array
      } else {
        findClaim(id);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      /* empty */
    }
  }, [address, findClaim]);

  useEffect(() => {
    init();
    if (balanceResult.data) {
      const Ubalance = balanceResult.data.value;
      setUserBalance(Number(formatUnits(Ubalance, 18)));
    }
  }, [balanceResult.data, init]);

  // Handle Change Function
  const handleCalculateTokens = (e) => {
    console.log("value...", e.target.value);
    const ethValue = e.target.value;
    if (!isNaN(ethValue) && ethValue >= 0) {
      setAmount(ethValue); // Update the amount state
      const tokenPriceInEth = 0.0000001;
      // Calculate number of tokens
      const tokens = ethValue / tokenPriceInEth;
      setYouWillGet(tokens.toFixed(2));
    }
  };

  // BuyHandler Function
  const buyHandler = async () => {
    try {
      setLoading(true);
      if (amount >= 0.05) {
        sendTransaction({
          to: "0x1EAb28D1513032e19fe088CD6FAA9F47b505ECC3",
          value: amount ? parseEther(amount.toString()) : "",
        });
      } else {
        const res = await preSaleWriteFunction(
          "placeOrder",
          [],
          parseUnits(amount, 18).toString()
        );
      }

      init();
      // console.log("res....", res);
      setAmount(0);
      setYouWillGet(0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(error.message, "error");
      console.log("error", error);
    }
  };

  // Handle Claim Function
  const handleClaim = async () => {
    try {
      setLoading(true);
      const res = await preSaleWriteFunction("claim", [orderId]);
      console.log("res.....", res);
      init();
      setLoading(false);
      console.log("res....", res);
    } catch (error) {
      setLoading(false);
      showToast(error.message, "error");
      console.log("error", error);
    }
  };

  // Connect Wallet
  const { open } = useWeb3Modal();
  const connectionHandler = useCallback(() => {
    if (address) {
      return open();
    }
    open();
  }, [address, open]);

  return (
    <>
      <Loading isLoading={loading} />
      <Toastify setAlertState={setAlertState} alertState={alertState} />

      <Box
        sx={{
          borderRadius: "12px",
          p: 2,
          backdropFilter: "blur(14px)", // Blurs the background
          background: "rgba(3, 87, 152, 0.5)", // Semi-transparent color
          zIndex: 100, // Set proper zIndex (without px)
          display: "flex",
          flexDirection: "column",
          gap: "10px 0px",
        }}
      >
        <Box sx={{ py: 2 }}>
          {presaleTimeStatus ? (
            <>
              {" "}
              <CountDownUp />
            </>
          ) : (
            <>
              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#fff",
                    textAlign: "center",
                    fontFamily: "CCMaladroit",
                    mb: 1,
                  }}
                >
                  Until Belugana Presale Opens
                </Typography>
              </Box>
              <CountdownTimer />
            </>
          )}
        </Box>
        {/* <Box>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontSize: "17px",
            fontWeight: 500,
            color: "#fff",
            textAlign: "center",
            fontFamily: "CCMaladroit",
          }}
        >
          usdc raised : $7,312,980.53
        </Typography>
      </Box>
      <Box
        sx={{
          height: "27px",
          borderRadius: "12px",
          position: "relative",
          py: 1,
        }}
      >
        <Typography
          sx={{
            width: "100%",
            fontWeight: "bold",
            color: "#fff",
            position: "absolute",
            textAlign: "center",
            fontSize: {
              xs: "12px",
              md: "14px",
            },
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontFamily: "CCMaladroit",
          }}
        >
          UNTIL PRESALE ENDS
        </Typography>
        <progress
          id="file"
          max="100"
          value="70"
          style={{
            width: "99.71%",
            marginTop: "1px",
            marginLeft: "1px",
          }}
        >
          60
        </progress>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: "10px 0", py: 2 }}
      >
        <Typography
          sx={{
            textTransform: "uppercase",
            color: "#fff",
            fontSize: "14px ",
            fontFamily: "CCMaladroit",
            textAlign: "center",
          }}
        >
          your purchased $BELU = 1,400,970
        </Typography>
      </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0px 20px",
            py: 1,
          }}
        >
          <Box sx={{ height: "2px", background: "#fff", width: "10%" }} />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "11px", sm: "14px", md: "14px" },
              fontFamily: "CCMaladroit",
              color: "#fff",
            }}
          >
            1 $BELU = 0.0000001 ETH
          </Typography>
          <Box sx={{ height: "2px", background: "#fff", width: "10%" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0px 7px",
            width: "100%",
          }}
        >
          <Button
            sx={{
              width: "40%",
              fontSize: "13px",
              background: "#fff",
              borderRadius: "15px",
              border: "1px solid transparent",
              color: "#000",
              "&:hover": {
                border: "1px solid #fff",
              },
            }}
          >
            <img
              src={ether}
              alt=""
              srcSet=""
              style={{ width: "30px", marginRight: "10px" }}
            />{" "}
            <Typography sx={{}}>ETH</Typography>
          </Button>
          <Button
            onClick={handleClaim}
            disabled={!orderId || claim}
            sx={{
              width: "40%",
              fontSize: "13px",
              background: "#fff",
              borderRadius: "15px",
              border: "1px solid transparent",
              color: "#000",
              "&:hover": {
                border: "1px solid #fff",
              },
              "&.Mui-disabled": {
                background: "#f0f0f0", // Optional: Style when disabled
                color: "#999",
              },
            }}
          >
            <Typography sx={{}}>Claim</Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0px 20px",
            py: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "11px", sm: "14px", md: "14px" },
              fontFamily: "CCMaladroit",
              color: "#fff",
            }}
          >
            ETH balance :{userBalance.toFixed(5)}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "16px", sm: "16px", md: "16px" },
            fontFamily: "CCMaladroit",
            color: "#fff",
            textAlign: "center",
          }}
        >
          Contract Address
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "16px", sm: "16px", md: "15.5px" },
            fontFamily: "CCMaladroit",
            color: "#fff",
            textAlign: "center",
            // mb: 2,
            overflow: "hidden",
            whiteSpace: "normal", // Allow wrapping
            wordWrap: "break-word",
          }}
        >
          0x280195d4EeaddD8166800E08F5f63CDC091DFcE0
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: { xs: "13px", sm: "16px", md: "13px" },
            fontFamily: "CCMaladroit",
            color: "#fff",
            textAlign: "center",
            mb: 2,
            overflow: "hidden",
            whiteSpace: "normal", // Allow wrapping
            wordWrap: "break-word",
          }}
        >
          (Do not send funds to this address.)
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "20px 10px",
            justifyContent: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px 0" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px 0px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "CCMaladroit",
                  fontSize: "12px",
                  fontWeight: "300",
                  ml: 2,
                }}
              >
                Pay With ETH
              </Typography>
              <Button
                onClick={() => {
                  setAmount(userBalance);
                  const tokenPriceInEth = 0.0000001;
                  // Calculate number of tokens
                  const tokens = userBalance / tokenPriceInEth;
                  setYouWillGet(tokens.toFixed(2));
                }}
                sx={{
                  color: "#fff",
                  fontFamily: "CCMaladroit",
                  fontSize: "12px",
                  fontWeight: "300",
                  p: 0,
                }}
              >
                Max
              </Button>
            </Box>

            <CustomField
              color="#000"
              placeholder="0"
              name="amount"
              image={ether}
              value={amount}
              onChange={handleCalculateTokens}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px 0" }}>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "CCMaladroit",
                fontSize: "12px",
                fontWeight: "300",
                ml: 2,
              }}
            >
              $BELU you receive
            </Typography>

            <CustomField
              color="#000"
              placeholder="0"
              name="youWillGet"
              image={logo}
              value={youWillGet}
              readOnly
              // onChange={onAmountChange}
            />
          </Box>
        </Box>

        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={{
            width: "100%",
            py: 1,
            px: 2,
            textAlign: "center",
            background: "#9763A9",
            color: "#fff",
            borderRadius: "12px",
          }}
        >
          Buy and stake for 154% reward
        </Button>
      </Box> */}
        <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
          {/* <Button
          sx={{
            width: "48%",
            py: 1,
            px: 2,
            textAlign: "center",
            background: "#9763A9",
            color: "#fff",
            borderRadius: "12px",
          }}
        >
          Buy Now
        </Button> */}
          {address ? (
            <>
              <Button
                onClick={buyHandler}
                sx={{
                  fontSize: "13px",
                  background: "#fff",
                  borderRadius: "15px",
                  border: "1px solid transparent",
                  width: "100%",
                  color: "#000",
                  fontFamily: "CCMaladroit",
                  // fontFamily: "CCMaladroit",
                  "&:hover": {
                    border: "1px solid #fff",
                  },
                }}
              >
                Buy With ETH
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={connectionHandler}
                sx={{
                  fontSize: "13px",
                  background: "#fff",
                  borderRadius: "15px",
                  border: "1px solid transparent",
                  width: "100%",
                  color: "#000",
                  fontFamily: "CCMaladroit",
                  // fontFamily: "CCMaladroit",
                  "&:hover": {
                    border: "1px solid #fff",
                  },
                }}
              >
                Connect Wallet
              </Button>
            </>
          )}
        </Box>
        {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px 0",
          pb: 2,
        }}
      >
        <Link style={{ color: "#fff", textAlign: "center" }}>
          Not Enough ETH? Top up Now
        </Link>
        <Typography sx={{ color: "#fff", textAlign: "center" }}>
          Powered by Web3Payments
        </Typography>
      </Box> */}
      </Box>
    </>
  );
};

export default BuyCard;
